<template>
  <div>
    <div class="interest-box">
      <!-- 权利过程 lable.PowerProcess.name-->
      <h3>{{ $t("lable.PowerProcess.name") }}</h3>
      <div
        class="goback-list"
        v-if="createdStatus == 'interestDetail'"
        @click="goBackList"
      >
        <!-- <<返回列表：权利过程 lable.PowerProcess.golist-->
        <a href="javascript:void(0);">{{ $t("lable.PowerProcess.golist") }}</a>
      </div>
      <div>
        <!--	lable.PowerProcess.Tips 权利过程是包含所有步骤（重大事件）的可自定义时间线，支持客服等工作人员必须完成所有步骤，才能解决支持的个案或者工作订单记录。在设置完成后，可以将其应用到权利中。 -->
        <p>
          {{ $t("lable.PowerProcess.Tips") }}
        </p>
        <!-- 在创建过程前，请 lable.PowerProcess.created.ago.please-->
        <!-- 创建重大事件 lable.PowerProcess.created.event-->
        <p>
          {{ $t("lable.PowerProcess.created.ago.please") + "&nbsp;" }}
          <a
            href="javascript:void(0);"
            class="created-big-event"
            @click="createdBigEvent"
            >{{ $t("lable.PowerProcess.created.event") }}</a
          >
        </p>
      </div>
      <!-- 权利过程列表 -->
      <div class="interestlist" v-if="createdStatus == 'interestList'">
        <div class="interestlist-top">
          <!-- 权利过程 lable.PowerProcess.name-->
          <!-- 新建权利过程  lable.PowerProcess.new.PowerProcess-->
          <h4>{{ $t("lable.PowerProcess.name") }}</h4>
          <el-button @click="addinterest">{{
            $t("lable.PowerProcess.new.PowerProcess")
          }}</el-button>
        </div>
        <div>
          <el-table
            :data="tableData"
            v-loading="tableLoading"
            style="width: 100%"
            :header-cell-style="{
              color: '#333333',
              background: '#F0F0F0',
              border: '1px solid #DCDCDC',
            }"
          >
            <!-- 权利过程名称 lable.PowerProcess.name1-->
            <el-table-column :label="$t('lable.PowerProcess.name1')">
              <template slot-scope="scope">
                <a
                  href="javascript:void(0);"
                  class="processname"
                  @click="goInterestDetail(scope.row.id)"
                  >{{ scope.row.name }}</a
                >
              </template>
            </el-table-column>
            <!-- 权利过程类型 lable.PowerProcess.type-->
            <el-table-column prop="type" :label="$t('lable.PowerProcess.type')">
            </el-table-column>
            <!-- 描述 label.appointmentdetail.wizard.title13-->
            <el-table-column
              prop="description"
              :label="$t('label.appointmentdetail.wizard.title13')"
            >
            </el-table-column>
          </el-table>
        </div>
        <!--    底部分页-->
        <div class="bottom_page">
          <div class="page-option">
            <div class="page-option-l">
              {{ page.total + "&nbsp;" + $t("label.file.items") }}
            </div>
            <div class="page-option-m">
              <el-button
                @click="previousPage"
                type="text"
                size="small"
                :disabled="page.pageNum === 1"
              >
                <!-- 上一页 -->
                {{ $t("label.page.previous") }}
              </el-button>
              <el-button
                @click="nextPage"
                type="text"
                size="small"
                :disabled="page.pageNum > pageNumTotal - 1"
              >
                <!--下一页  -->
                {{ $t("label.page.next") }}
              </el-button>
            </div>
            <div class="page-option-r">
              <!-- 第 -->
              <!-- {{ $t("label.mr.title.button.group1") }} -->
              <!-- 页 -->
              {{ $t("label.brief.page") }}
              <input type="text" v-model="jumpNum" style="margin-left: 8px" />
              <el-button
                type="text"
                size="small"
                class="skip-page-btn"
                @click="jumpPage"
                >GO</el-button
              >
              <!-- 共    -->
              {{ $t("label.multilang.totalpage2") }}
              {{ pageNumTotal }}
              <!-- 页 -->
              {{ $t("label.brief.page") }}
            </div>
          </div>
        </div>
      </div>
      <!-- 新建权利过程第一步 -->
      <div class="build-type" v-if="createdStatus == 'addInterestFirst'">
        <!-- 您想要创建什么类型的权利过程？ lable.PowerProcess.type.created-->
        <h4>{{ $t("lable.PowerProcess.type.created") }}</h4>
        <div class="build-type-middle">
          <!-- 权利过程类型 lable.PowerProcess.type-->
          <span>{{ $t("lable.PowerProcess.type") }}</span>
          <div class="created-type">
            <el-select v-model="typevalue">
              <el-option
                v-for="item in interesttype"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <span>
            <svg aria-hidden="true" class="icon">
              <use href="#icon-Tips"></use>
            </svg>
          </span>
        </div>
        <div class="sub-button">
          <!-- 下一步 label.next-->
          <!-- 取消 label.emailsync.button.cancel-->
          <el-button type="primary" @click="addinteresNext">{{
            $t("label.next")
          }}</el-button>
          <el-button @click="goBackList">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
        </div>
      </div>
      <!-- 新建权利过程第二步 -->
      <div
        v-if="createdStatus == 'addInterestSecond'"
        class="addInterestSecond"
      >
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <div class="addInterestSecond-header">
            <!-- 权利过程编辑 lable.PowerProcess.edit-->
            <h4>{{ $t("lable.PowerProcess.edit") }}</h4>
            <div class="sub-button">
              <!-- 保存 component.telerecord.button.save-->
              <el-button
                type="primary"
                @click="submitForm('ruleForm')"
                :loading="submitLoading"
                >{{ $t("component.telerecord.button.save") }}</el-button
              >
              <!-- 取消 label.emailsync.button.cancel-->
              <el-button @click="goBackInterestType" :loading="submitLoading">{{
                $t("label.emailsync.button.cancel")
              }}</el-button>
            </div>
            <div class="RequiredMsg">
              <span>*</span>
              <!-- 必填信息 label.requiredinfo-->
              <span>{{ $t("label.requiredinfo") }}</span>
            </div>
          </div>
          <div class="addInterestSecond-middle">
            <!-- 权利过程信息 lable.PowerProcess.message-->
            <h4>{{ $t("lable.PowerProcess.message") }}</h4>
            <div class="interest-form">
              <div class="interest-process-name">
                <div class="Required-type">
                  <span>*</span>
                  <!-- 权利过程名称 lable.PowerProcess.name1-->
                  <span>{{ $t("lable.PowerProcess.name1") }}</span>
                </div>
                <el-form-item prop="InterestName">
                  <el-input v-model="ruleForm.InterestName"></el-input>
                </el-form-item>
              </div>
              <div class="interest-type">
                <!-- 权利过程类型 lable.PowerProcess.type-->
                <span>{{ $t("lable.PowerProcess.type") }}</span>
                <span>{{ describeName }}</span>
              </div>
              <div class="describe-msg">
                <!-- 描述 label.appointmentdetail.wizard.title13-->
                <span>{{ $t("label.appointmentdetail.wizard.title13") }}</span>
                <el-input
                  v-model="describeValue"
                  type="textarea"
                  maxlength="200"
                  size="mini"
                ></el-input>
              </div>
              <div class="isEnable">
                <!-- 启用 label.emailtocloudcc.button.enable-->
                <span>{{ $t("label.emailtocloudcc.button.enable") }}</span>
                <el-checkbox v-model="EnableValue"></el-checkbox>
              </div>
            </div>
          </div>
          <div class="addInterestSecond-bottom">
            <!-- 高级选项 advancedoptions-->
            <h4>{{ $t("advancedoptions") }}</h4>
            <div class="addInterestSecond-bottom-box">
              <div class="addInterestSecond-process">
                <div class="in-Work-order">
                  <span>*</span>
                  <!-- {{ describeName }}进入过程 lable.PowerProcess.enter.the.process-->
                  <span>{{
                    $t("lable.PowerProcess.enter.the.process", {
                      describeName: describeName,
                    })
                  }}</span>
                </div>
                <div>
                  <!-- 请选择 label.tabpage.pleaseSelectz-->
                  <el-select
                    v-model="choicenumber"
                    :placeholder="$t('label.tabpage.pleaseSelectz')"
                    @change="chioceIn"
                  >
                    <el-option
                      v-for="item in choiceTime"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="tips">
                  <svg aria-hidden="true" class="icon">
                    <use href="#icon-Tips"></use>
                  </svg>
                </div>
              </div>
              <div
                class="timeField"
                v-if="isTimeField"
                :style="{
                  marginLeft: typevalue == 'WorkOrder' ? '154px' : '122px',
                }"
              >
                <el-form-item prop="choiceid">
                  <!-- 请选择 label.tabpage.pleaseSelectz-->
                  <el-select
                    v-model="ruleForm.choiceid"
                    :placeholder="$t('label.tabpage.pleaseSelectz')"
                  >
                    <el-option
                      v-for="item in timeField"
                      :key="item.id"
                      :label="item.labelName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="addInterestSecond-process quit-process">
                <div class="in-Work-order">
                  <span>*</span>
                  <!-- {{ describeName }}退出过程  lable.PowerProcess.leave.the.process-->
                  <span>{{
                    $t("lable.PowerProcess.leave.the.process", {
                      describeName: describeName,
                    })
                  }}</span>
                </div>
                <div>
                  <!-- 请选择 label.tabpage.pleaseSelectz-->
                  <el-select
                    v-model="quitenumber"
                    :placeholder="$t('label.tabpage.pleaseSelectz')"
                    @change="choiceQuit"
                  >
                    <el-option
                      v-for="item in quiteTime"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="tips">
                  <svg aria-hidden="true" class="icon">
                    <use href="#icon-Tips"></use>
                  </svg>
                </div>
              </div>
              <!-- 筛选查重范围 -->
              <div v-if="isCustom">
                <div
                  class="Calculation-rules"
                  :style="{
                    marginLeft: typevalue == 'WorkOrder' ? '154px' : '122px',
                  }"
                >
                  <!-- 需要满足以下条件：lable.PowerProcess.Meet.the.conditions -->
                  <h3>{{ $t("lable.PowerProcess.Meet.the.conditions") }}</h3>
                  <div>
                    <!-- 运算符 label.operator-->
                    <!-- 值 label.reportsSubscriptionPage.condition.value-->
                    <!-- 字段 label.tabpage.field-->
                    <span>{{ $t("label.tabpage.field") }}</span>
                    <span>{{ $t("label.operator") }}</span>
                    <span>{{
                      $t("label.reportsSubscriptionPage.condition.value")
                    }}</span>
                  </div>
                </div>
                <div class="range">
                  <!-- 筛选字段文本框 -->
                  <el-form>
                    <el-form-item
                      v-for="(val, index) in pushData"
                      :key="val.id"
                      :label-width="
                        typevalue == 'WorkOrder' ? '155px' : '122px'
                      "
                    >
                      <div class="vl">
                        <!-- 筛选字段 开始 -->
                        <span class="Requiredtype">*</span>
                        <!-- 选择字段 setup.layout.label.selectedfield-->
                        <el-select
                          v-model="val.fieldId"
                          :placeholder="$t('setup.layout.label.selectedfield')"
                          filterable
                          popper-class="option"
                          :popper-append-to-body="false"
                        >
                          <el-option
                            v-for="(items, indexs) in choseFieldList"
                            :key="indexs"
                            :label="items.labelName"
                            :value="items.id"
                            @click.native="handleCommand(val, items, items)"
                          >
                            <!-- val:传递当前字段的信息 -->
                            <!-- items:传递 -->
                          </el-option>
                        </el-select>
                        <!-- 筛选条件 开始 -->
                        <!-- 选择条件 label.report.select.condition-->
                        <el-select
                          v-model="val.op"
                          :placeholder="$t('label.report.select.condition')"
                          class="select"
                          filterable
                          popper-class="option"
                          :popper-append-to-body="false"
                        >
                          <el-option
                            v-for="item in conditions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <EditableCell
                        style="
                          width: 200px;
                          display: inline-block;
                          margin-left: 10px;
                          position: relative;
                        "
                        ref="editableCell"
                        :options="selector"
                        :value="val.val"
                        :input-type="val.inputType"
                        :max="1000000000000000000"
                        :origin-type="filter"
                        :prefix="prefix"
                        :fieldId="val.fieldId"
                        :objid="val.objid"
                        @selectes="val.fieldId === '' ? '' : selectes(val)"
                        @remoteSearch="remoteSearch(val, index)"
                        @remoteMethods="remoteMethods"
                      >
                        {{ val.fieldId }}
                      </EditableCell>
                      <!-- 加减按钮组 -->
                      <div style="display: flex; margin-left: 5px">
                        <el-button
                          type="info"
                          size="mini"
                          @click="updateItem('reduce', index)"
                          v-if="showBut[index]"
                          >-</el-button
                        >
                        <!-- 清空 label.emailtocloudcc.clear-->
                        <el-button
                          type="info"
                          size="mini"
                          @click="clear"
                          v-if="index == 0"
                          >{{ $t("label.emailtocloudcc.clear") }}</el-button
                        >
                        <!-- <button type=" button" @click="updateItem('reduce', index)" v-if="showBut[index]"></button> -->
                        <el-button
                          type="info"
                          size="mini"
                          @click="updateItem('add', index)"
                          v-if="
                            index + 1 == pushData.length && pushData.length <= 4
                          "
                          >+</el-button
                        >
                        <!-- <button type="button" @click="updateItem('add', index)" v-if="index + 1 == pushData.length">
                +
              </button> -->
                      </div>
                    </el-form-item>
                  </el-form>
                  <div>
                    <!-- 搜索 label.searchs-->
                    <el-dialog
                      :title="$t('label.searchs')"
                      custom-class="dialog-form-wrapper"
                      width="60%"
                      top="5%"
                      style="height: 91%"
                      :visible.sync="showSearchTable"
                      :lock-scroll="true"
                      :close-on-click-modal="false"
                      :modal="true"
                      append-to-body
                    >
                      <search-table
                        ref="searchTable"
                        :fieldId="fieldId"
                        :checked="checked"
                        :relevant-objid="relevantObjId"
                        :relevant-prefix="relevantPrefix"
                        @changeSelect="changeSelect"
                      />
                    </el-dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-button sub-button-boeder">
            <!-- 保存 component.telerecord.button.save-->
            <!-- 取消 label.emailsync.button.cancel-->
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              :loading="submitLoading"
              >{{ $t("component.telerecord.button.save") }}</el-button
            >
            <el-button @click="goBackInterestType" :loading="submitLoading">{{
              $t("label.emailsync.button.cancel")
            }}</el-button>
          </div>
        </el-form>
      </div>
      <!-- 权利过程详情页 -->
      <div v-if="createdStatus == 'interestDetail'" class="interestDetail">
        <div class="interestDetail-header">
          <!-- 权利过程详细信息 lable.PowerProcess.detail.message-->
          <h4>{{ $t("lable.PowerProcess.detail.message") }}</h4>
          <div>
            <!-- 编辑 label.emailtocloudcc.edit-->
            <!-- 删除 label.emailtocloudcc.delete-->
            <el-button @click="editInterest">{{
              $t("label.emailtocloudcc.edit")
            }}</el-button>
            <el-button @click="deleteInterest(detailInterestId)">{{
              $t("label.emailtocloudcc.delete")
            }}</el-button>
          </div>
          <div></div>
        </div>
        <div class="interestDetail-midlle">
          <ul class="interest-onlyready-left">
            <li>
              <div class="interest-name-onlyready">
                <!-- 权利过程名称 lable.PowerProcess.name1-->
                <span>{{ $t("lable.PowerProcess.name1") }}</span>
                <span>{{ detailInterestName }}</span>
              </div>
              <div class="interest-Enable-onlyready">
                <!-- 启用 label.emailtocloudcc.button.enable-->
                <span>{{ $t("label.emailtocloudcc.button.enable") }}</span>
                <el-checkbox
                  v-model="isEnableValue"
                  :disabled="true"
                ></el-checkbox>
              </div>
            </li>
            <li>
              <div class="interest-type-onlyready">
                <!-- 权利过程类型 lable.PowerProcess.type-->
                <span>{{ $t("lable.PowerProcess.type") }}</span>
                <span>{{ detailInterestType }}</span>
              </div>
            </li>
            <li>
              <div class="interest-describe-onlyready">
                <!-- 描述 label.appointmentdetail.wizard.title13-->
                <span>{{ $t("label.appointmentdetail.wizard.title13") }}</span>
                <span>{{ detailDescribe }}</span>
              </div>
            </li>
            <li>
              <div class="interest-Workorder-onlyready">
                <!-- {{ describeName }}进入过程 lable.PowerProcess.enter.the.process-->
                <span>{{
                  $t("lable.PowerProcess.enter.the.process", {
                    describeName: detailInterestType,
                  })
                }}</span>
                <span>{{ detailInterestIn }}</span>
              </div>
              <div class="interest-goback-onlyready">
                <!-- {{ describeName }}退出过程  lable.PowerProcess.leave.the.process-->
                <span>{{
                  $t("lable.PowerProcess.leave.the.process", {
                    describeName: detailInterestType,
                  })
                }}</span>
                <span>{{ detailInterestOut }}</span>
              </div>
            </li>
            <li>
              <div class="interest-time-onlyready">
                <!-- 权利过程工作时间 lable.PowerProcess.worktime-->
                <span>{{ $t("lable.PowerProcess.worktime") }}</span>
              </div>
            </li>
            <li>
              <div class="interest-createder-onlyready">
                <!-- 创建人 label.tag.manage.createdby-->
                <span>{{ $t("label.tag.manage.createdby") }}</span>
                <div>
                  <a href="javascript:void(0);">{{ createName }} </a>
                  <span>{{ createTime | formatDate }}</span>
                </div>
              </div>
              <div class="interest-lastedit-onlyready">
                <!-- 上次修改人 label.lasttimemodifier-->
                <span>{{ $t("label.lasttimemodifier") }}</span>
                <div class="lastedit-time">
                  <a href="javascript:void(0);">{{ editName }}</a>
                  <span>{{ editTime | formatDate }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 设置重大事件 -->
        <div class="interestDetail-bottom">
          <div v-if="bigEventList.length == 0">
            <div class="setting-big-event">
              <!-- 设置重大事件 lable.PowerProcess.setevent-->
              <h4>{{ $t("lable.PowerProcess.setevent") }}</h4>
              <!-- 新建 label.import.page1.newcreate-->
              <el-button @click="settingEvent">{{
                $t("label.import.page1.newcreate")
              }}</el-button>
            </div>
            <!-- 没有可显示的记录 chatter.norecord-->
            <div class="nothing">{{ $t("chatter.norecord") }}</div>
          </div>
          <div v-if="bigEventList.length != 0">
            <div class="setting-big-event">
              <h4>{{ $t("lable.PowerProcess.setevent") }}</h4>
              <!-- 新建 label.import.page1.newcreate-->
              <el-button @click="settingEvent">{{
                $t("label.import.page1.newcreate")
              }}</el-button>
            </div>
            <div class="big-event-list">
              <el-table
                :data="bigEventList"
                style="width: 100%"
                :header-cell-style="{
                  color: '#333333',
                  background: '#F0F0F0',
                  border: '1px solid #DCDCDC',
                }"
              >
                <!-- 操作 label.import.index.operation-->
                <el-table-column :label="$t('label.import.index.operation')">
                  <template slot-scope="scope">
                    <!-- 编辑 label.emailtocloudcc.edit-->
                    <a
                      href="javascript:void(0);"
                      class="edit-event"
                      v-if="scope.row.enable == 'true'"
                      @click="editEvent(scope.row.id)"
                      >{{ $t("label.emailtocloudcc.edit") }}</a
                    >
                    <span class="edit-midle" v-if="scope.row.enable == 'true'"
                      >|</span
                    >
                    <!-- 删除 label.emailtocloudcc.delete-->
                    <a
                      href="javascript:void(0);"
                      class="delete-event"
                      v-if="scope.row.enable == 'true'"
                      @click="deleteEvent(scope.row.id)"
                      >{{ $t("label.emailtocloudcc.delete") }}</a
                    >
                    <span class="edit-right" v-if="scope.row.enable == 'true'"
                      >|</span
                    >
                    <!-- 查看 label.emailtocloudcc.button.view-->
                    <a
                      href="javascript:void(0);"
                      class="see-event"
                      @click="seeEvent(scope.row.id)"
                      >{{ $t("label.emailtocloudcc.button.view") }}</a
                    >
                  </template>
                </el-table-column>
                <!-- 顺序 label.newprocess5.seq-->
                <el-table-column
                  prop="seq"
                  :label="$t('label.newprocess5.seq')"
                >
                </el-table-column>
                <!-- 名称 label.emailtocloudcc.name-->
                <el-table-column
                  prop="name"
                  :label="$t('label.emailtocloudcc.name')"
                >
                </el-table-column>
                <!-- 周期类型 label.service.cycle.type-->
                <el-table-column
                  prop="type"
                  :label="$t('label.service.cycle.type')"
                >
                  <template slot-scope="scope">
                    {{ typeObj[scope.row.type] }}
                  </template>
                </el-table-column>
                <!-- 完成重大事件的分钟 lable.PowerProcess.complete-->
                <el-table-column
                  prop="timetrigger"
                  :label="$t('lable.PowerProcess.complete')"
                >
                </el-table-column>
                <!-- 开始时间 label.import.index.starttime-->
                <el-table-column
                  prop="starttimetype"
                  :label="$t('label.import.index.starttime')"
                >
                  <template slot-scope="scope">
                    {{ typeStr[scope.row.starttimetype] }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import operator from "@/config/enumCode/operator.js";
import inputType from "@/config/enumCode/inputType.js";
import * as majorEventApi from "./api.js";
import * as CommonObjApi from "./api";
import {
  GetCurrencyInfo,
  GetViewInfo,
  getRecordType,
  getSelectValue,
  GetViewGetSelectValue,
  getobjfield
} from "./api";
export default {
  data() {
    return {
      //周期类型
      typeObj: {
        //独立 label.service.major.event.independent
        1: this.$i18n.t("label.service.major.event.independent"),
        //序列 label.service.major.event.oreder
        2: this.$i18n.t("label.service.major.event.oreder"),
        //无周期 label.service.without.cycle
        3: this.$i18n.t("label.service.without.cycle"),
      },
      //权利类型
      typeStr: {
        //权利过程 lable.PowerProcess.name
        1: this.$i18n.t("lable.PowerProcess.name"),
        //重大事件条件  lable.PowerProcess.event.condition
        2: this.$i18n.t("lable.PowerProcess.event.condition"),
      },
      tableLoading: true, // 表格loading
      //权利过程类型
      typevalue: "cloudcccase",
      //切换权利页面
      createdStatus: "interestList",
      describeValue: "",
      describeName: "",
      choicenumber: "1",
      quitenumber: "2",
      choiceid: "",
      //详情页权利名称
      detailInterestName: "",
      //详情页权利描述
      detailDescribe: "",
      //详情页过程类型
      detailInterestType: "",
      //详情页进入过程值
      detailInterestIn: "",
      //详情页退出过程
      detailInterestOut: "",
      //详情页权利过程id
      detailInterestId: "",
      //创建人时间
      createTime: "",
      //修改人时间
      editTime: "",
      //创建人名称
      createName: "",
      //修改人名称
      editName: "",
      //权利过程进入下拉框状态
      inSelectval: "",
      //权利过程退出下拉框状态
      outSelectval: "",
      //权利过程自定义进入条件id
      inId: "",
      //权利过程自定义字段回显
      fieldData: [],
      //是否为编辑下的保存
      iseditSave: false,
      EnableValue: false,
      submitLoading: false,
      isEnableValue: false,
      // isDefault: false,
      //是否为时间字段
      isTimeField: false,
      //是否为自定义退出过程
      isCustom: false,
      timeField: [],
      // refushstatus: 0,
      ruleForm: {
        InterestName: "",
        choiceid: "",
      },
      rules: {
        InterestName: [
          // 请输入名称 vue_label_homepage_enter_name
          {
            required: true,
            message: this.$i18n.t("vue_label_homepage_enter_name"),
            trigger: "blur",
          },
        ],
        // 请选择字段 label.processsetting.please.select.field
        choiceid: [
          {
            required: true,
            message: this.$i18n.t("label.processsetting.please.select.field"),
            trigger: "blur",
          },
        ],
      },
      interesttype: [
        //个案 UG.Case.01
        {
          label: this.$i18n.t("UG.Case.01"),
          value: "cloudcccase",
        },
        //工作订单 lable.PowerProcess.Workorder
        {
          label: this.$i18n.t("lable.PowerProcess.Workorder"),
          value: "WorkOrder",
        },
      ],
      tableData: [],
      bigEventList: [],
      choiceTime: [],
      quiteTime: [],
      // 保存筛选字段
      pushData: [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ],
      pushDatas: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        inputType: "input",
      },
      //筛选条件
      choseFieldList: [],
      conditions: [],
      selector: [],
      filter: "filter",
      showBut: [false],
      showSearchTable: false,
      fieldId: "",
      checked: false, //多选框选择和取消
      relevantObjId: "",
      relevantPrefix: "",
      page: {
        total: 100, // 总条数
        pageNum: 1, // 当前页数
      },
      pageNumTotal: 10, // 总页数
      jumpNum: 1, // 要跳转的页数
    };
  },
  filters: {
    //时间转换
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return `${y}/${MM}/${d} ${h}:${m}:${s}`;
    },
  },
  components: {
    EditableCell,
  },
  mounted() {
    this.getInterestList(1);
  },
  watch: {
    pushData(val) {
      if (val.length == 1) {
        this.showBut[0] = false;
      }
    },
  },
  created() {
    if (this.$route.query.isGoDetail) {
      this.goInterestDetail(this.$route.query.goDetailId);
    }
  },
  methods: {
    //查看重大事件
    async seeEvent(params) {
      let resultData = await majorEventApi.getSingleMajorEvent({
        majorEventSetId: params,
      });
      if (resultData.result) {
        this.$router.push({
          path: "/systemSettings/setuppage/seePage",
          query: {
            eventDetail: JSON.stringify(resultData.data.majoeEventMaps[0]),
            name: this.detailInterestName,
            id: this.detailInterestId,
            type: this.detailInterestType,
          },
        });
      }
    },
    remoteMethods(val, fieldId, objId) {
      let params = {
        fieldId: fieldId,
        objId: objId,
        prefix: this.prefix,
      };
      CommonObjApi.getLookupInfo(params).then((res) => {
        let data = {
          name: val,
          objId: res.data.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        CommonObjApi.queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selector = options;
        });
      });
    },
    //权力过程下重大事件编辑回显
    async editEvent(params) {
      let resultData = await majorEventApi.getSingleMajorEvent({
        majorEventSetId: params,
      });
      if (resultData.result) {
        this.$router.push({
          path: "/systemSettings/setuppage/NewMajorEvents",
          query: {
            eventDetail: JSON.stringify(resultData.data.majoeEventMaps[0]),
            name: this.detailInterestName,
            id: this.detailInterestId,
            type: this.detailInterestType,
          },
        });
      }
    },
    //删除权利过程中重大事件
    async deleteEvent(id) {
      let resultData = await majorEventApi.deleteEventInEntitlementProcess({
        id: id,
      });
      if (resultData.result) {
        this.bigEventList = this.bigEventList.filter((item) => {
          return item.id != id;
        });
      }
    },
    //跳转创建重大事件页
    createdBigEvent() {
      this.$router.push({
          path: "/systemSettings/setuppage/importantEvent/edit",
          query: {
            isEditMode:false
          },
        });
    },
    //获取与权利过程关联的重大事件列表
    async getEventInEntitlementProcessList(id) {
      let resultData = await majorEventApi.getEventListInEntitlementProcess({
        entitlementProcessId: id,
      });
      this.bigEventList = resultData.data.majorEventlist;
    },
    //清空筛选器数据
    clear() {
      this.pushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
    },
    //编辑权利过程
    editInterest() {
      this.ruleForm.InterestName = this.detailInterestName;
      this.EnableValue = this.isEnableValue;
      this.describeValue = this.detailDescribe;
      this.describeName = this.detailInterestType;
      let choicevalue = [
        {
          //基于${this.detailInterestType}的创建日期 lable.PowerProcess.created.time
          label: this.$i18n.t("lable.PowerProcess.created.time", {
            detailInterestType: this.detailInterestType,
          }),
          value: "1",
        },
        {
          //基于${this.detailInterestType}的日期时间字段 lable.PowerProcess.time.filed
          label: this.$i18n.t("lable.PowerProcess.time.filed", {
            detailInterestType: this.detailInterestType,
          }),
          value: "2",
        },
      ];
      let quiteChoicevalue = [
        {
          //基于${this.detailInterestType}的关闭时间 lable.PowerProcess.close.time
          label: this.$i18n.t("lable.PowerProcess.close.time", {
            detailInterestType: this.detailInterestType,
          }),
          value: "2",
        },
        {
          //基于自定义条件 lable.PowerProcess.custom
          label: this.$i18n.t("lable.PowerProcess.custom"),
          value: "1",
        },
      ];
      this.choiceTime = choicevalue;
      this.quiteTime = quiteChoicevalue;
      this.choicenumber = this.inSelectval;
      this.quitenumber = this.outSelectval;
      //进入过程自定义字段回显
      //个案 UG.Case.01
      if (this.inSelectval == "2") {
        let fieldid =
          this.detailInterestType == this.$i18n.t("UG.Case.01")
            ? "201100000005024CxOSz"
            : "WorkOrderstdobj02021";
        this.getobjfieldList(fieldid);
        this.isTimeField = true;
        this.ruleForm.choiceid = this.inId;
      }
      //退出过程自定义字段回显
      if (this.outSelectval == "1") {
        this.isCustom = true;
        if (this.fieldData.length != 0) {
          this.pushData = [];
        }
        this.fieldData.forEach((ele, index) => {
          this.pushData.push({
            id: index,
            fieldId: ele.fieldId,
            op: ele.operator,
            val: {
              value: ele.value,
            },
            inputType: "input",
          });
        });
        this.pushData.forEach((nav) => {
          this.choseFieldList.forEach((em) => {
            if (nav.fieldId == em.id) {
              nav.type = em.schemefieldType;
              nav.inputType = inputType[em.schemefieldType];
              this.conditions = operator.TYPE[nav.type].getScreeningOpeTypes();
            }
          });
        });
        if (this.pushData.length >= 0 && this.pushData.length <= 1) {
          this.showBut = [false];
        } else {
          this.showBut.push(true);
          this.showBut[0] = true;
        }
      }
      this.iseditSave = true;
      this.createdStatus = "addInterestSecond";
    },
    //删除权利过程
    async deleteInterest(id) {
      let resultData = await majorEventApi.deleteEntitlementProcess({ id: id });
      if (resultData.result) {
        this.goBackList();
      }
    },
    //权利过程详情
    async goInterestDetail(id) {
      let resultData = await majorEventApi.getEntitlementProcessDetail({
        id: id,
      });
      if (resultData.result) {
        this.isEnableValue = JSON.parse(
          resultData.data.EntitlementProcess.isenable
        ); //是否启用
        this.detailInterestName = resultData.data.EntitlementProcess.name; //名称
        this.detailDescribe = resultData.data.EntitlementProcess.description; //描述
        //个案 UG.Case.01
        //工作订单 this.$i18n.t('lable.PowerProcess.Workorder')
        this.detailInterestType =
          resultData.data.EntitlementProcess.type == "cloudcccase"
            ? this.$i18n.t("UG.Case.01")
            : this.$i18n.t("lable.PowerProcess.Workorder"); //类型
        //基于${this.detailInterestType}的创建日期 lable.PowerProcess.created.time
        //基于${this.detailInterestType}的日期时间字段 lable.PowerProcess.time.filed
        this.detailInterestIn =
          resultData.data.EntitlementProcess.enterprocesstype == "1"
            ? this.$i18n.t("lable.PowerProcess.created.time", {
                detailInterestType: this.detailInterestType,
              })
            : this.$i18n.t("lable.PowerProcess.time.filed", {
                detailInterestType: this.detailInterestType,
              }); //进入过程
        //基于自定义条件 lable.PowerProcess.custom
        //基于${this.detailInterestType}的关闭时间 lable.PowerProcess.close.time
        this.detailInterestOut =
          resultData.data.EntitlementProcess.exitprocesstype == "1"
            ? this.$i18n.t("lable.PowerProcess.custom")
            : this.$i18n.t("lable.PowerProcess.close.time", {
                detailInterestType: this.detailInterestType,
              }); //退出过程
        this.detailInterestId = resultData.data.EntitlementProcess.id; //id
        this.createTime = resultData.data.EntitlementProcess.createdate; //创建人时间
        this.editTime = resultData.data.EntitlementProcess.lastmodifydate; //修改人时间
        this.createName = resultData.data.EntitlementProcess.createbyidName; //创建人名称
        this.editName = resultData.data.EntitlementProcess.lastmodifybyidName; //修改人名称
        this.inSelectval = resultData.data.EntitlementProcess.enterprocesstype; //过程进入下拉框状态
        this.outSelectval = resultData.data.EntitlementProcess.exitprocesstype; //过程退出下拉框状态
        this.inId = resultData.data.EntitlementProcess.enterprocessfieldid; //权利流程进入id
        this.fieldData = resultData.data.conditionList; //权利流程自定义字段
        this.createdStatus = "interestDetail";
      }
      this.getEventInEntitlementProcessList(this.detailInterestId);
    },
    //获取权利过程列表
    async getInterestList() {
      let resultdata = await majorEventApi.getEntitlementProcessList({
        pageNum: this.page.pageNum,
        pageSize: 5,
      });
      if (resultdata.result) {
        resultdata.data.entitlementProcessList.forEach((item) => {
          if (item.type == "cloudcccase") {
            // 个案 UG.Case.01
            //工作订单 this.$i18n.t('lable.PowerProcess.Workorder')
            item.type = this.$i18n.t("UG.Case.01");
          } else {
            item.type = this.$i18n.t("lable.PowerProcess.Workorder");
          }
        });
        this.tableData = resultdata.data.entitlementProcessList;
        this.page.total = resultdata.data.totalCount;
        this.pageNumTotal = Math.ceil(resultdata.data.totalCount / 5);
        this.tableLoading = false;
      }
    },
    submitForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //保存权利过程
            this.saveInterest();
          } else {
            return false;
          }
        });
      });
    },
    //保存权利过程
    
    async saveInterest() {
      this.submitLoading = false;
      //入参拼接
      let params = {};
      params.name = this.ruleForm.InterestName;
      params.type = this.typevalue;
      params.description = this.describeValue;
      params.isenable = JSON.stringify(this.EnableValue);
      params.enterprocesstype = this.choicenumber;
      params.enterprocessfieldid =
        this.choicenumber == "1" ? "" : this.ruleForm.choiceid;
      params.exitprocesstype = this.quitenumber;
      //是否是编辑后保存
      if (this.iseditSave) {
        params.id = this.detailInterestId;
      }
      let arrData = [];
      this.pushData.map((item) => {
        let object = {
          fieldId: item.fieldId,
          op: item.op,
          val: "",
        };
        arrData.push(object);
      });
      if (this.$refs.editableCell) {
        this.$refs.editableCell.forEach((item, index) => {
          if (item.editValue !== "") {
            arrData[index].val = item.editValue;
          } else {
            arrData[index].val = item.value;
          }
        });
      }
      if (arrData.length > 0) {
        // 验证筛选器是否填写完整
        for (var key in arrData) {
          var item = arrData[key];
          if (item.fieldId !== "") {
            if (item.val.value == "" || item.op == "") {
              this.$message({
                showClose: true,
                type: "warning",
                // 请填写完整筛选条件
                message: this.$i18n.t(
                  "vue_label_checkduplicate_complete_filter_rules"
                ),
              });
              return false;
            } else if (item.val.value != "" && item.op != "") {
              if (Array.isArray(item.val)) {
                item.val = item.val.toString();
              }
              let str = "1";
              if (arrData.length == 1) {
                str = "1";
              } else if (arrData.length > 1) {
                arrData.map((item, index) => {
                  if (index >= 1) {
                    str += ` AND ${index + 1} `;
                    // str = str.replace(/AND $/, "");
                  }
                });
              }
              params.conditionValues = JSON.stringify({
                data: arrData,
                filter: str,
              });
            }
          }
        }
      } else {
        params.conditionValues = "";
      }
      let resultStatus = await majorEventApi.saveEntitlementProcess(params);
      if (resultStatus.result) {
        if (this.iseditSave) {
          this.goInterestDetail(this.detailInterestId);
        } else {
          this.goInterestDetail(resultStatus.data.id);
        }
        this.iseditSave = false;
        this.submitLoading = false;
        this.clearValue();
      }
    },
    //获取对象字段列表
    async getobjfieldList(id) {
      let fieldlist = await getobjfield(id);
      this.timeField = fieldlist.data.filter((item) => {
        return item.type == "F";
      });
    },
    //选择退出过程
    choiceQuit(item) {
      if (item == "1") {
        this.isCustom = true;
        this.choseField();
      } else {
        this.isCustom = false;
      }
    },
    //选择进入过程
    chioceIn(item) {
      if (item == "2") {
        this.isTimeField = true;
        //个案 UG.Case.01
        let fieldid =
          this.describeName == this.$i18n.t("UG.Case.01")
            ? "201100000005024CxOSz"
            : "WorkOrderstdobj02021";
        this.getobjfieldList(fieldid);
      } else {
        this.isTimeField = false;
      }
    },
    //设置重大事件
    settingEvent() {
      this.$router.push({
        path: "/systemSettings/setuppage/NewMajorEvents",
        query: {
          name: this.detailInterestName,
          id: this.detailInterestId,
          type: this.detailInterestType,
        },
      });
    },
    //新建权利过程跳转
    addinterest() {
      this.createdStatus = "addInterestFirst";
    },
    //新建权利第二步
    addinteresNext() {
      //个案 UG.Case.01
      //工作订单 lable.PowerProcess.Workorder
      this.describeName =
        this.typevalue == "cloudcccase"
          ? this.$i18n.t("UG.Case.01")
          : this.$i18n.t("lable.PowerProcess.Workorder");
      let choicevalue = [
        {
          //基于${this.describeName}的创建日期 lable.PowerProcess.created.time
          label: this.$i18n.t("lable.PowerProcess.created.time", {
            detailInterestType: this.describeName,
          }),
          value: "1",
        },
        {
          //基于${this.describeName}的日期时间字段 lable.PowerProcess.time.filed
          label: this.$i18n.t("lable.PowerProcess.time.filed", {
            detailInterestType: this.describeName,
          }),
          value: "2",
        },
      ];
      let quiteChoicevalue = [
        {
          //基于${this.describeName}的关闭时间 lable.PowerProcess.close.time
          label: this.$i18n.t("lable.PowerProcess.close.time", {
            detailInterestType: this.describeName,
          }),
          value: "2",
        },
        {
          //基于自定义条件 lable.PowerProcess.custom
          label: this.$i18n.t("lable.PowerProcess.custom"),
          value: "1",
        },
      ];
      this.choiceTime = choicevalue;
      this.quiteTime = quiteChoicevalue;
      this.createdStatus = "addInterestSecond";
    },
    //返回权利过程列表
    goBackList() {
      this.createdStatus = "interestList";
      this.typevalue = "cloudcccase";
      this.page.pageNum = 1;
      this.jumpNum = 1;
      this.getInterestList(1);
    },
    //返回上级页面
    goBackInterestType() {
      this.clearValue();
      this.createdStatus = "addInterestFirst";
    },
    //清除数据
    clearValue() {
      this.ruleForm.InterestName = "";
      this.describeValue = "";
      this.EnableValue = false;
      this.choicenumber = "1";
      this.quitenumber = "2";
      this.isTimeField = false;
      this.isCustom = false;
      this.choiceid = "";
      let copypushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
      this.pushData = copypushData;
    },
    //判断选择添加如果选择字段获取的值为S则调用S对象,获取的值为D则调用D对象,获取的值为F则调用F对象,获取的值为O则调用O对象
    async handleCommand(item, key, index) {
      this.selector = [];
      let keys = "";
      if (key.schemefieldType === "Z") {
        keys = key.expressionType;
      } else {
        keys = key.schemefieldType;
      }
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      this.pushData.map((item) => {
        if (index.id === item.fieldId) {
          // item.op = "";
          // item.type =
          key.schemefieldType === "Z"
            ? key.expressionType
            : key.schemefieldType;
          // this.$refs.editableCell[indexs].editValue = "";
        }
      });
      this.pushData.forEach((items) => {
        if (item.id === items.id) {
          item.inputType = inputType[keys];
        }
      });
      // 当前选中字段类型
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      if (keys == "ct") {
        let res = await GetCurrencyInfo();
        res.data.forEach((item) => {
          let obj = {
            label: item.currencyname,
            value: item.currencycode,
          };
          this.selector.push(obj);
        });
      }
    },
    
    async choseField() {
      //个案 UG.Case.01
      let fieldid =
        this.describeName == this.$i18n.t("UG.Case.01")
          ? "201100000005024CxOSz"
          : "WorkOrderstdobj02021";
      let params = {
        objId: fieldid,
      };
      let result = await GetViewInfo(params);
      this.choseFieldList = result.data.conditionAllFieldList;
    },
    remoteSearch(item, index) {
      if (item.fieldId !== "") {
        this.fieldId = item.fieldId;
      }
      this.rowIndex = index + 1;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    updateItem(handle, index) {
      if (handle == "add") {
        if (this.pushData.length <= 4) {
          this.pushData.push({
            ...this.pushDatas,
            // id: this.num,
          });
          if (this.pushData.length >= 0 && this.pushData.length <= 1) {
            this.showBut = [false];
          } else {
            this.showBut.push(true);
            this.showBut[0] = true;
          }
        }
      } else {
        this.pushData.splice(index, 1);
        // this.$refs.editableCell.splice(index, 1);
      }
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
      // let value = {
      //   value: row.data.id,
      // };
      let options = {
        label: row.data.id,
        value: row.data.name,
      };
      this.$set(this.pushData[this.rowIndex - 1], "val", options);
      this.showSearchTable = false;
    },
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      }
      // else if(val.type === "L" && val.fieldId === "ffe202085ACBDD8EusnH"){
      //   this.getCountrySelectValue()
      // }
      else {
        if (val.fieldId === "ffe20120329185449704") {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codekey,
              value: item.codekey,
            };
            arr.push(obj);
            this.selector = arr;
          });
        }
      }
    },
    // 获取国家或地区选项列表值
    
    getCountrySelectValue() {
      getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.selector = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    
    async getRecordTypes() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      let res = await getRecordType(params);
      if (res.result) {
        res.data.recordTypeList.forEach((item) => {
          let obj = {
            label: item.name,
            value: item.name,
          };
          arr.push(obj);
          this.selector = arr;
        });
      }
    },
    // 分页相关操作
    //上一页
    previousPage() {
      if (this.page.pageNum > 1) {
        this.tableLoading = true;
        this.page.pageNum = this.page.pageNum - 1;
        this.jumpNum = this.page.pageNum;
        this.getInterestList();
      } else {
        // this.$message.info("已经是第一页");
        this.$message.info(this.$i18n.t("label.manageAll.pagenum1"));
      }
    },
    //下一页
    nextPage() {
      if (this.page.pageNum < this.pageNumTotal) {
        this.tableLoading = true;
        this.page.pageNum = this.page.pageNum + 1;
        this.jumpNum = this.page.pageNum;
        this.getInterestList();
      } else {
        // this.$message.info("已经是最后一页");
        this.$message.info(this.$i18n.t("label.manageAll.pagenum2"));
      }
    },
    // 跳转页面
    jumpPage() {
      this.jumpNum = Number(this.jumpNum);
      if (this.jumpNum <= this.pageNumTotal) {
        this.tableLoading = true;
        this.page.pageNum = this.jumpNum;
        this.getInterestList();
        // this.jumpNum = "";
      } else {
        this.$message.error(this.$i18n.t("label.field.input.number"));

        // this.jumpNum = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialogStyle.scss";
* {
  margin: 0;
}
::v-deep .el-table th.gutter {
  display: table-cell !important;
}
::v-deep .el-table colgroup.gutter {
  display: table-cell !important;
}
::v-deep .el-form-item{
  margin-bottom: 0;
}
::v-deep table {
  // border-collapse: collapse !important;
}
.range {
  .Requiredtype:first-child {
    color: red;
  }
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
  ::v-deep .created-type {
    width: 120px;
    margin-right: 6px;
  }
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }
  ::v-deep .select {
    margin-left: 10px;
  }
  ::v-deep .el-date-editor {
    display: none !important;
  }
}
.interest-box {
  padding: 20px 10px;
  h3 {
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .goback-list {
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #333333;
  }
  p:first-child {
    margin-bottom: 10px;
  }
  .created-big-event {
    text-decoration: underline;
  }

  .interestlist {
    margin-top: 24px;
    background-color: #f5f5f5;
    padding: 15px 10px;
    .interestlist-top {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      ::v-deep .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
      }
      h4 {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
      }
    }
    .processname {
      text-decoration: underline;
    }
    .bottom_page {
      height: 40px;
      padding: 0 15px;
      background: #ffffff;
      border-radius: 0 0 3px 3px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .page-option {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .page-option-l {
          display: flex;
          align-items: center;
        }
        .page-option-m {
          display: flex;
          .el-button {
            border: none;
            font-size: 12px;
            // &:hover {
            //   background: none;
            // }
          }
        }
        .page-option-r {
          display: flex;
          align-items: center;
          input {
            width: 32px;
            height: 20px;
            outline: none;
            border: 1px solid #ccc;
            padding: 0 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .skip-page-btn {
            margin: 0 5px;
            height: 22px;
            padding: 0 5px;
            border: 1px solid #ccc;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .build-type {
    margin-top: 24px;
    h4 {
      width: 100%;
      padding: 10px;
      background-color: #f0f0f0;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      border: 1px solid #dcdcdc;
    }
    .build-type-middle {
      display: flex;
      align-items: center;
      padding: 34px 100px;
      background-color: #f5f5f5;
      border: 1px solid #dcdcdc;
      span:first-child {
        font-size: 16px;
        color: #333333;
        margin-right: 20px;
      }
      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      ::v-deep .el-input__icon {
        line-height: 30px;
      }
      ::v-deep .created-type {
        width: 120px;
        margin-right: 6px;
      }
    }
    .sub-button {
      width: 100%;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #dcdcdc;
      padding: 10px 0;
      ::v-deep .el-button {
        height: 26px;
        line-height: 26px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .addInterestSecond {
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    margin-top: 24px;
    .addInterestSecond-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f5f5;
      border: 1px solid #dcdcdc;
      padding: 15px 10px;
      h4 {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        // border-top: 1px solid #dcdcdc;
        // border-bottom: 1px solid #dcdcdc;
      }
      .sub-button {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
          height: 26px;
          line-height: 26px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .RequiredMsg {
        span:first-child {
          color: red;
        }
        span:last-child {
          font-size: 16px;
          color: #151515;
          font-weight: bold;
        }
      }
    }
    .addInterestSecond-middle {
      border: 1px solid #dcdcdc;
      h4 {
        padding: 10px;
        font-size: 14px;
        color: #333333;
        background-color: #f0f0f0;
        font-weight: bold;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
      }
      .interest-form {
        background-color: #f5f5f5;
        padding: 19px 0 25px 92px;
        .interest-process-name {
          display: flex;
          margin-bottom: 12px;
          ::v-deep .el-input__inner {
            width: 225px;
            height: 30px;
            line-height: 30px;
          }
          .Required-type {
            display: flex;
            align-items: center;
            margin-right: 20px;
          }
          span {
            white-space: nowrap;
          }
          span:first-child {
            color: red;
          }
          span:last-child {
            font-size: 16px;
            color: #151515;
          }
        }
        .interest-type {
          margin-bottom: 12px;
          span:first-child {
            display: inline-block;
            width: 102px;
            text-align: right;
            font-size: 16px;
            color: #151515;
            margin-right: 20px;
          }
          span:last-child {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
          }
        }
        .describe-msg {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          ::v-deep .el-textarea__inner {
            width: 591px;
            height: 30px;
          }
          span {
            display: inline-block;
            white-space: nowrap;
            width: 114px;
            text-align: right;
            font-size: 16px;
            color: #151515;
            margin-right: 20px;
          }
        }
        .isEnable {
          span {
            display: inline-block;
            white-space: nowrap;
            width: 102px;
            text-align: right;
            font-size: 16px;
            color: #151515;
            margin-right: 20px;
          }
        }
      }
    }

    .addInterestSecond-bottom {
      border: 1px solid #dcdcdc;
      h4 {
        padding: 10px;
        font-size: 14px;
        color: #333333;
        background-color: #f0f0f0;
        font-weight: bold;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
      }
      .addInterestSecond-bottom-box {
        background-color: #f5f5f5;
        padding: 20px 0 30px 60px;
        .addInterestSecond-process {
          display: flex;
          align-items: center;
          ::v-deep .el-input__inner {
            width: 303px;
            height: 30px;
            line-height: 30px;
          }
          ::v-deep .el-input__icon {
            line-height: 30px;
          }
          ::v-deep .created-type {
            width: 120px;
            margin-right: 6px;
          }
          .in-Work-order {
            margin-right: 20px;
            span:first-child {
              color: red;
            }
            span:last-child {
              color: #151515;
              font-size: 16px;
            }
          }
          .tips {
            margin-left: 10px;
          }
        }
        .quit-process {
          margin-top: 20px;
        }
        .timeField {
          margin-top: 5px;
          margin-left: 122px;
          ::v-deep .el-input__inner {
            width: 303px;
            height: 30px;
            line-height: 30px;
          }
          ::v-deep .el-input__icon {
            line-height: 30px;
          }
          ::v-deep .created-type {
            width: 120px;
            margin-right: 6px;
          }
        }
      }
      .Calculation-rules {
        margin-left: 122px;
        h3 {
          font-size: 14px;
          color: #333333;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        span {
          font-size: 14px;
          color: #333333;
          font-weight: bold;
        }
        span:first-child {
          margin-right: 170px;
        }
        span:last-child {
          margin-left: 152px;
        }
      }
    }
    .sub-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13.5px 0;
      background-color: #f5f5f5;
      ::v-deep .el-button {
        height: 26px;
        line-height: 26px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .sub-button-boeder {
      border-top: 1px solid #dcdcdc;
      border-left: 1px solid #dcdcdc;
      border-right: 1px solid #dcdcdc;
    }
  }
  .interestDetail {
    margin-top: 22px;
    .interestDetail-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4 {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
      }
      ::v-deep .el-button {
        height: 26px;
        line-height: 26px;
        padding: 0 20px;
      }
    }
    .interestDetail-midlle {
      margin-top: 34px;
      .interest-onlyready-left {
        li {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #dcdcdc;
          padding: 10px 0;
        }
        li:first-child {
          padding-top: 0;
        }
        .interest-name-onlyready {
          width: 50%;
          padding-left: 86px;
          span:first-child {
            margin-right: 20px;
            font-size: 15px;
            color: #333333;
            width: 112px;
            text-align: right;
            display: inline-block;
          }
          span:last-child {
            font-size: 16px;
            color: #333333;
            font-weight: bold;
          }
        }
        .interest-type-onlyready {
          width: 50%;
          padding-left: 86px;
          span:first-child {
            margin-right: 20px;
            font-size: 14px;
            color: #333333;
            width: 112px;
            text-align: right;
            display: inline-block;
          }
          span:last-child {
            font-size: 16px;
            color: #333333;
            font-weight: bold;
          }
        }
        .interest-describe-onlyready {
          width: 100%;
          padding-left: 86px;
          display: flex;
          span:last-child {
            display: inline-block;
            width: 100%;
            text-align: left;
            word-break: break-all;
          }
          span:first-child {
            margin-right: 20px;
            font-size: 14px;
            color: #333333;
            white-space: nowrap;
            width: 112px;
            flex-shrink: 0;
            text-align: right;
            display: inline-block;
          }
        }
        .interest-Workorder-onlyready {
          width: 50%;
          padding-left: 86px;
          span:first-child {
            margin-right: 20px;
            font-size: 14px;
            color: #333333;
            width: 112px;
            text-align: right;
            display: inline-block;
          }
          span:last-child {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
          }
        }
        .interest-time-onlyready {
          width: 50%;
          padding-left: 86px;
          span:first-child {
            margin-right: 20px;
            font-size: 14px;
            color: #333333;
            width: 112px;
            text-align: right;
            display: inline-block;
          }
        }
        .interest-createder-onlyready {
          display: flex;
          align-items: center;
          width: 50%;
          padding-left: 86px;
          span:first-child {
            margin-right: 20px;
            font-size: 14px;
            color: #333333;
            width: 112px;
            text-align: right;
            display: inline-block;
          }
          a {
            text-decoration: underline;
          }
        }
        .interest-Enable-onlyready {
          width: 50%;
          span:first-child {
            font-size: 16px;
            color: #333333;
            margin-right: 20px;
            width: 112px;
            text-align: right;
            display: inline-block;
          }
        }
        .interest-default-onlyready {
          width: 50%;
          span:first-child {
            font-size: 16px;
            color: #333333;
            margin-right: 20px;
            width: 112px;
            text-align: right;
            display: inline-block;
          }
        }
        .interest-goback-onlyready {
          width: 50%;
          span:first-child {
            font-size: 14px;
            color: #333333;
            margin-right: 20px;
            width: 112px;
            text-align: right;
            display: inline-block;
          }
          span:last-child {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
          }
        }
        .interest-lastedit-onlyready {
          width: 50%;
          display: flex;
          align-items: center;
          span:first-child {
            margin-right: 20px;
            width: 112px;
            text-align: right;
            display: inline-block;
          }
          .lastedit-time {
            display: flex;
            align-items: center;
          }
          a {
            text-decoration: underline;
          }
        }
      }
    }
    .interestDetail-bottom {
      margin-top: 23px;
      background-color: #f5f5f5;
      padding: 10px;
      .setting-big-event {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          font-size: 14px;
          color: #333333;
          font-weight: bold;
        }
        ::v-deep .el-button {
          height: 26px;
          line-height: 26px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .nothing {
        padding: 10px;
        border: 1px solid #dcdcdc;
        margin-top: 10px;
        border-radius: 3px;
      }
      .big-event-list {
        border: 1px solid #dcdcdc;
        margin-top: 15px;
        .edit-event {
          font-size: 14px;
          margin-right: 10px;
        }
        .edit-midle {
          color: #1890ff;
        }
        .delete-event {
          font-size: 14px;
          margin-left: 10px;
        }
        .edit-right {
          color: #1890ff;
          margin: 0 10px;
        }
        .see-event {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
